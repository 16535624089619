$('.table-tabs')
    .not('[table-tabs-init]')
    .attr('table-tabs-init', true)
    .each(function() {
        const $tableTabs = $(this);
        const $toggles = $tableTabs.children('dt');

        $toggles.first().addClass('active');
        $toggles.on('click', e => {
            const $toggle = $(e.target);

            if ($toggle.hasClass('active')) {
                return;
            }

            $toggles.removeClass('active');
            $(e.target).addClass('active');
        });
    });
