class DatePicker {
    constructor({ allowedDays, onSelectDate }) {
        this.$el = $('#datepicker');
        this.totalMonths = 3;
        this.allowedDays = allowedDays;
        this.onSelectDate = onSelectDate;
        this.setup();
    }

    setup() {
        var that = this;

        const currentDate = new Date();
        const minDate = currentDate;
        /* Add 3 months but set day to 0, which translates to 'whole next month'.
         * If it's December (month === 11) change year and set month to 1 (February) and day to 0.
         */
        const maxDate =
            currentDate.getMonth() !== 11
                ? new Date(
                      currentDate.getFullYear(),
                      currentDate.getMonth() + this.totalMonths,
                      0
                  )
                : new Date(currentDate.getFullYear() + 1, 1, 0);

        this.$el.datepicker({
            numberOfMonths: this.totalMonths,
            beforeShowDay: date => (function(aDate) {
                var dayIndex = that.allowedDays.findIndex(d => d.day === that.dateParser(aDate));
                var day = null;
                if (dayIndex > -1) {
                    day = that.allowedDays[dayIndex];

                    return [
                        true,
                        day.cssClass,
                        day.message
                    ];
                }
                    
                return [
                     false,
                    ''
                ];
            })(date),
            onSelect: this.onSelectDate,
            defaultDate: currentDate,
            minDate,
            maxDate
        });

        this.$el.datepicker($.datepicker.regional['el']);
    }

    // return date in format of type '22-1-2019'
    dateParser(date) {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const formattedDate = `${day}-${month}-${year}`;

        return formattedDate;
    }
}

export default DatePicker;
