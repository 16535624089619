import './sass/main.scss';
import './rendezvous';
import './table-tabs';
import Clinics from './rendezvous/rendezvous-clinics';

$('.main-nav .nav-item').each(function() {
    if ($(this).children('ul').length > 0) {
        $('<a href="#" class="fa fa-angle-down text-white"></a>').insertAfter(
            $(this).children('.nav-link')
        );
    }
});
$('.main-nav').on('click', '[class*="fa-angle-"]', function(e) {
    e.preventDefault();
    $(this)
        .toggleClass('fa-angle-up fa-angle-down')
        .siblings('ul')
        .slideToggle();
});




