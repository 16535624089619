class Clinics {
    constructor() {
        this.$loading = $('.clinic-seat-form .loading');
        this.$results = $('#divSearchResults');
        this.RENDEZVOUS_SEARCH_URL =
            '/User-Services/Rendezvous/Clinic-Search/Rendezvous-Search?s=';
        this.CLINICS_URL =
            '/CMSWebParts/Nortech/OnlineRendezvous/OnlineRedezvous.asmx/GetClinics';

        this.searchClinics = this.searchClinics.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
    }

    searchClinics(e) {
        const searchTerm = $(e.target)
            .val()
            .trim();

        if (searchTerm.length < 3) {
            this.$results.html('');
            return;
        }

        $.ajax({
            type: 'POST',
            url: this.CLINICS_URL,
            contentType: 'application/json',
            data: JSON.stringify({ searchTerm }),
            beforeSend: () => this.$loading.show(),
            success: this.onSuccess,
            complete: () => this.$loading.hide()
        });
    }

    onSuccess(response) {
        this.$results.html('');

        if ($.isEmptyObject(response) || $.isEmptyObject(response.d)) {
            return;
        }

        const data = JSON.parse(response.d);

        if ($.isArray(data) && $.isArray(data[0].Table)) {
            const items = data[0].Table;

            items.map(item => {
                const guid = item.OnDutyClinicSeatGuid;
                const text = item.OnDutyClinicSeatDisplayName;
                const classNames = 'list-group-item list-group-item-action';

                this.$results.append(
                    `<a href="${this.RENDEZVOUS_SEARCH_URL}${guid}" class="${classNames}">${text}</a>`
                );
            });
        }
    }
}

export default Clinics;