export default [
    {
        hour: '08:00',
        slots: [
            { slot: '08:00', status: 1, slotText: 'Διαθέσιμη ώρα' },
            { slot: '08:03', status: 2, slotText: 'Κατειλημμένη ώρα' },
            { slot: '08:10', status: 3, slotText: 'Κατειλημμένη από εσάς ώρα' },
            { slot: '08:15', status: 3, slotText: 'Κατειλημμένη από εσάς ώρα' },
            { slot: '08:40', status: 1, slotText: 'Διαθέσιμη ώρα' },
            { slot: '08:55', status: 1, slotText: 'Διαθέσιμη ώρα' }
        ]
    },
    {
        hour: '09:00',
        slots: [
            { slot: '09:00', status: 1, slotText: 'Διαθέσιμη ώρα' },
            { slot: '09:03', status: 2, slotText: 'Κατειλημμένη ώρα' },
            { slot: '09:10', status: 1, slotText: 'Διαθέσιμη ώρα' },
            { slot: '09:15', status: 3, slotText: 'Κατειλημμένη από εσάς ώρα' },
            { slot: '09:40', status: 1, slotText: 'Διαθέσιμη ώρα' },
            { slot: '09:55', status: 1, slotText: 'Διαθέσιμη ώρα' }
        ]
    }
];
