import DatePicker from './rendezvous-datepicker';
import timeslots from './timeslots.mock';

const containerId = '#rendezvous-submission';

function setupRendezvous() {
    const allowedDays = JSON.parse($('#allowed-days').val());
    const onDutyClinicSeatId = parseInt($('#online-clinic-seat-id').val(), 10);
    const guestID = parseInt($('#GuestID').val(), 10);
    const $messageModal = $('#messageModal');
    const TIMETABLE_URL =
        '/CMSWebParts/Nortech/OnlineRendezvous/OnlineRedezvous.asmx/GetTimetable';
    const SUBMIT_RENDEZVOUS_URL =
        '/CMSWebParts/Nortech/OnlineRendezvous/OnlineRedezvous.asmx/SubmitRendezvous';

    window.app = new Vue({
        el: containerId,
        data: {
            date: '',
            summary: '',
            timeslots: [],
            selectedTimeslot: {},
            isLoading: false,
            hasErrors: false,
            submitMessage: ''
        },
        computed: {
            time: function() {
                return this.selectedTimeslot.Slot;
            },
            wsDate() {
                const [day, month, year] = this.date.split('/');
                const date = [month, day, year].join('-');

                return date;
            }
        },
        methods: {
            onSelectDate: function(date) {
                this.date = date;
                this.getTimeTable();
            },
            onSelectTime: function(e, timeslot) {
                e.preventDefault();
                this.selectedTimeslot = timeslot;
                window.scrollTo(0,document.body.scrollHeight);
            },
            getTimeTable: function() {      
                this.resetTimetable();
                const data = {
                    date: this.wsDate,
                    onDutyClinicSeatId,
                    guestID
                };

                $.ajax({
                    type: 'GET',
                    url: TIMETABLE_URL,
                    // contentType: 'application/json',
                    data,
                    beforeSend: () => (this.isLoading = true),
                    success: timeslots => {
                        try {
                            this.timeslots = JSON.parse(
                                timeslots.children[0].innerHTML
                            );
                        } catch (e) {
                            this.timeslots = [];
                        }
                    },
                    complete: () => (this.isLoading = false)
                });
            },
            onSubmitAppointment: function() {
                const data = {
                    dateStr: this.wsDate,
                    timeStr: this.time,
                    onDutyClinicSeatId,
                    guestID
                };

                // console.log('submit rendezvous request data: ', data);

                $.ajax({
                    type: 'POST',
                    url: SUBMIT_RENDEZVOUS_URL,
                    data,
                    beforeSend: () => (this.isLoading = true),
                    success: result => {
                        const $result = $(result.children[0]);
                        const status = $result.find('RendezvousStatus').text();
                        this.submitMessage = $result.find('Message').text();

                        if (status !== 'Success') {
                            this.hasErrors = true;
                        }

                        this.showModal();

                        // window.result = result;
                        // console.log(
                        //     'submit rendezvous response: ',
                        //     $result.html()
                        // );
                    },
                    complete: () => (this.isLoading = false)
                });
            },
            resetTimetable: function() {
                this.time = '';
                this.timeslots = [];
                this.selectedTimeslot = '';
                this.hasErrors = false;
                this.submitMessage = '';
            },
            showModal: function(e) {
                e && e.preventDefault();
                $('#messageModal').modal('show');
            },
            hideModal: function(e) {
                e && e.preventDefault();

                if (this.hasErrors) {
                    $('#messageModal').modal('hide');
                    this.getTimeTable();
                } else {
                    window.location.href =
                        '/User-Services/Rendezvous/Rendezvous-History';
                }
            }
        },
        mounted: function() {
            new DatePicker({
                allowedDays,
                onSelectDate: this.onSelectDate
            });
            setupEvents();
        }
    });
}

function setupEvents() {
    $(containerId)
        .on('click', '.time-list .btn-toggle', e => {
            e.preventDefault();
            const $this = $(e.target);
            const $listItem = $this.closest('.list-item');
            const $openedListItems = $('.time-list .list-item--open').not(
                $listItem
            );

            $openedListItems.trigger('close');

            $listItem.hasClass('list-item--open')
                ? $listItem.trigger('close')
                : $listItem.trigger('open');
        })
        .on('open', '.time-list .list-item', e => {
            $(e.target)
                .addClass('list-item--open')
                .find('.list-item-main')
                .slideDown();
        })
        .on('close', '.time-list .list-item', e => {
            $(e.target)
                .removeClass('list-item--open')
                .find('.list-item-main')
                .slideUp();
        });
}


export default setupRendezvous;
