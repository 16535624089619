
import setupRendezvous from './rendezvous';

import Clinics from './rendezvous-clinics';

if ($('#rendezvous-submission').length > 0) {
    setupRendezvous();
}

var clinics = null;

$(document).on('keyup', '#txtSearch', {}, initializeSearchClinics);

function initializeSearchClinics() {
    if (clinics == null)
    {
        clinics = new Clinics();
    }
    $('#txtSearch').on('keyup', clinics.searchClinics);
}
